<template>
<el-dialog v-model="visible" :title="title" custom-class="curve-update-dialog" :close-on-click-modal="false" :before-close="close">
  <el-form :model="form" ref="ruleFormRef" :rules="rules" v-loading="loading">
    <el-form-item label="曲线组名称：" prop="txtLxcgCurveGroupName">
      <el-input v-model="form.txtLxcgCurveGroupName" placeholder="请填写曲线组名称，最多20字符" maxlength="20" />
    </el-form-item>
    <el-form-item label="曲线组说明：" prop="txtLxcgCurveGroupDesc">
      <el-input v-model="form.txtLxcgCurveGroupDesc" maxlength="200" :autosize="{minRows: 10, maxRows: 20}" show-word-limit type="textarea" placeholder="请填写关于该组曲线的描述说明，最多200字符" />
    </el-form-item>
  </el-form>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="close('cancel')">取 消</el-button>
      <el-button type="primary" @click="close('confirm')">确 认</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 曲线组管理 - 新增/编辑 曲线组
import { useStore } from 'vuex';
import { ElMessage, FormInstance, } from 'element-plus';
import { reactive, toRefs, ref, computed, onMounted } from "vue";
import { addCurveVersion, getCurveVersionDetail, editCurveVersion } from "api/apis.js";
export default {
  props: {
    updateData: {
      type: Object,
      default: function () {
        return { type: "add" };
      }
    },
  },
  setup(prop, ctx) {
    const store = useStore();

    // 表单校验 - 曲线组名称
    const curveGroupNamePattern = (rule, value, callback) => {
      let result = value.replace(/\s/g, "");
      if ((result || "") == "") {
        callback("曲线组名称不可为空");
      } else {
        callback();
      }
    };

    const state = reactive({
      ruleFormRef: ref(FormInstance),
      visible: true,
      loading: false,
      title: computed(() => prop.updateData.type == "edit" ? "编辑曲线组 - " + prop.updateData.txtLxcgCurveGroupName : "新增曲线组"),
      form: {
        txtLxcgCurveGroupName: "",
        txtLxcgCurveGroupDesc: ""
      },
      rules: {
        txtLxcgCurveGroupName: [
          { required: true, message: "曲线组名称不可为空", trigger: "blur" },
          { required: true, validator: curveGroupNamePattern, trigger: "blur" }
        ]
      }
    });

    onMounted(() => {
      // 编辑情况 获取详情
      if (prop.updateData.type == "edit") {
        getDetail();
      }
    })

    // 获取 批次详情
    const getDetail = () => {
      state.loading = true;
      getCurveVersionDetail({ lxcgCurveGroupId: prop.updateData.lxcgCurveGroupId }).then(response => {
        if (response.code == 200) {
          state.form = response.data;
        } else {
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    const close = type => {
      if (type == "confirm") {
        state.ruleFormRef.validate(valid => {
          if (valid) {
            // 校验成功
            state.loading = true;
            let param = {
              txtLxcgCurveGroupName: state.form.txtLxcgCurveGroupName,
              txtLxcgCurveGroupDesc: state.form.txtLxcgCurveGroupDesc.trim()
            };
            // 判断是新增还是编辑
            if (prop.updateData.type == "add") {
              // 是新增
              param.txtCreator = store.getters.userInfo ? store.getters.userInfo.name : "系统用户";
              addCurveVersion(param).then(response => {
                if (response.code == 200) {
                  ElMessage.success("新增成功");
                  ctx.emit("close", true);
                } else {
                  ElMessage.error(response.message);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              })
            } else {
              // 是编辑
              param.txtUpdater = store.getters.userInfo ? store.getters.userInfo.name : "系统用户";
              param.lxcgCurveGroupId = state.form.lxcgCurveGroupId;
              editCurveVersion(param).then(response => {
                if (response.code == 200) {
                  ElMessage.success("编辑成功");
                  ctx.emit("close", true);
                } else {
                  ElMessage.error(response.message);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              })
            }
          }
        });
      } else {
        ctx.emit("close", false);
      }
    };

    return {
      ...toRefs(state),
      close,
      getDetail
    }
  }
}
</script>

<style lang="scss">
.curve-update-dialog {
  width: 40%;

  .el-form {
    .el-form-item {
      margin-left: 14px;

      &:last-child {
        .el-form-item__label {
          padding-left: 11px;
        }
      }

      .el-form-item__label {
        width: 110px;
        text-align: left;
      }
    }
  }
}
</style>
